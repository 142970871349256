import { Button, MenuItem, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormSelect from '../../../../components/FormSelect';
import { useSecteurStore } from '../../../../stores/secteur';

const defaultValues = {
  secteur: '',
  technicien: '',
};

const ChangeSecteur = ({ insee_com, lieudit, secteur, technicien, onPreview, onDismiss }) => {
  const methods = useForm({ defaultValues });
  const {
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    setError,
    control,
  } = methods;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const secteurs = useSecteurStore((state) => state.secteurs);
  const techniciens = useSecteurStore((state) => state.techniciens);

  useEffect(() => {
    setValue('secteur', secteur);
    setValue('technicien', technicien);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '10px',
        padding: '10px',
      }}
    >
      <FormProvider {...methods}>
        <FormSelect
          name="secteur"
          control={control}
          label="Secteur"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {secteurs.map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="technicien"
          control={control}
          label="RRC"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {techniciens.map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </FormSelect>
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            const newSecteur = getValues('secteur');
            const newTech = getValues('technicien');

            if (newSecteur !== '' && newTech !== '') {
              clearErrors();

              // update cached data
              onPreview(insee_com, lieudit, newSecteur, newTech);
              onDismiss();
            } else {
              setError('form', {
                type: 'manual',
                message: 'Veuillez choisir un secteur et un RRC',
              });
            }
          }}
          variant="contained"
        >
          {'Prévisualiser'}
        </Button>
        <Button onClick={() => onDismiss()} variant="contained">
          {'Annuler'}
        </Button>
        {/* <Button
                onClick={() => {
                  const secteur = getValues('secteur');
  
                  if (secteur !== '' && secteur !== prevSecteur) {
                    delete cache.data[campagne];
                    clearErrors();
                    return handleSubmit(onSubmit)();
                  } else {
                    setError('form', {
                      type: 'manual',
                      message: 'Veuillez choisir un secteur différent',
                    });
                  }
                }}
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Valider'}
              </Button> */}
      </FormProvider>
    </Paper>
  );
};

export default ChangeSecteur;
