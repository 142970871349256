import { axios } from '../lib/axios';
import Keycloak from 'keycloak-js';
import { KEYCLOAK_URL_BASE, CLIENT_ID } from '../config';

const keycloak = new Keycloak({
  url: `${KEYCLOAK_URL_BASE}keycloak`,
  realm: 'cristalunion',
  clientId: CLIENT_ID
});

const initSecurity = async () => {
  return new Promise((resolve, reject) => {

    const token = JSON.parse(localStorage.getItem("keycloak_token"));
    if (token) {
      axios.defaults.headers.common.Authorization = 'Bearer' + ' ' + token.access_token;
      localStorage.removeItem("keycloak_token");
      resolve();
    } else {
      keycloak.init({ onLoad: 'login-required', responseMode: 'query', checkLoginIframe: false })
        .then((auth) => {
          if (!auth) {
            reject(new Error('Authentication failed'));
          } else if (!keycloak.hasResourceRole('ROLE_USER')) {
            document.getElementsByTagName("body")[0].innerHTML = "Vous n'avez pas les doits pour acc&eacute;der &agrave; l'application !";
          } else {
            console.debug('Authenticated');
            axios.defaults.headers.common.Authorization = keycloak.tokenParsed.typ + ' ' + keycloak.token;
            // SecuritySvc.token = keycloak.token;
            resolve();
          }
        })
        .catch((err) => {
          console.error(err);
          console.error('Authentication failed');
          reject(err);
        });
    }
  });
};

export default { initSecurity, keycloak }