import { axios } from '../../../lib/axios';

const getContourCommunesSecteur = async () => {
  const response = await axios.get(`/parcelles-api/communes/secteur`);
  return response;
};

const getSurfaceCommunes = async (campagne) => {
  const subdomain = window.location.host.split('.')[0];
  if (subdomain === 'go-qual') {
    campagne = '2021';
  }

  const response = await axios.get(`/agronomie/surfacescommunes?campagne=${campagne}`);
  return response?.agronomie;
};

export { getContourCommunesSecteur, getSurfaceCommunes };
