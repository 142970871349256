import Axios from 'axios';
import { CRISTAL_CLOUD_URL, GEO_API_GOUV_URL, WXS_IGN_URL, BASIC } from '../config';
import { addNotification } from '../stores/notifications';
import SecurityService from '../security/SecurityService';
// import storage from '../utils/storage';

const authRequestInterceptor = async (config) => {
  try {
    const refreshed = await SecurityService.keycloak.updateToken(70);
    if (refreshed) {
      console.debug('Token refreshed before request');
    } else {
      console.debug('Token still valid');
    }

    config.headers = config.headers || {};
    config.headers.Authorization = `${SecurityService.keycloak.tokenParsed.typ} ${SecurityService.keycloak.token}`;
  } catch (err) {
    console.error('Failed to refresh the token', err);
  }

  if (!config.returnsFile) {
    config.headers.Accept = config.headers.Accept || 'application/json';
  }

  return config;
};

const axios = Axios.create({
  baseURL: CRISTAL_CLOUD_URL,
});

const axiosBasic = Axios.create({
  baseURL: CRISTAL_CLOUD_URL,
  config: { headers: {
    authorization : `Basic ${BASIC}`
  }}
});

const geoApiGouv = Axios.create({
  baseURL: GEO_API_GOUV_URL,
});

const wxsIgn = Axios.create({
  baseURL: WXS_IGN_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    if (response.data.error_description || response.data.errormsg) {
      const message = response.data.error_description || response.data.errormsg;
      addNotification({
        variant: 'error',
        message,
      });

      return Promise.reject(message);
    }

    return response.data;
  },
  (error) => {
    // if (error.response.status === 401 || error.response.status === 403) {
    //   window.location.href = `${CRISTAL_CLOUD_URL}login-sap/?uid=${UID}`;
    // } else {
      const message = error.response?.data?.message || error.message;
      addNotification({
        variant: 'error',
        message,
      });

      return Promise.reject(error);
    }
  // }
);

geoApiGouv.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    addNotification({
      variant: 'error',
      message,
    });

    return Promise.reject(error);
  }
);

wxsIgn.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    addNotification({
      variant: 'error',
      message,
    });

    return Promise.reject(error);
  }
);

export { axios, geoApiGouv, wxsIgn, axiosBasic };
