import { Avatar } from '@mui/material';
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader';
import RelateOp from 'jsts/org/locationtech/jts/operation/relate/RelateOp';
import { nanoid } from 'nanoid';
import Collection from 'ol/Collection';
import { getCenter } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import VectorSource from 'ol/source/Vector';
import { getArea } from 'ol/sphere';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { disableAreaSearch, enableAreaSearch } from '../../stores/areaSearch';
import {
  addInteraction,
  removeAllInteractions,
  removeInteraction,
  useInteractionsStore,
} from '../../stores/interactions';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import { addItems, setItems, useItemsStore } from '../../stores/menuItems';
import { addNotification, closeNotification } from '../../stores/notifications';
import { toggleSideBar } from '../../stores/sidebar';
import { getViewExtent, getViewZoom, useViewStore } from '../../stores/view';
import { setWaitDialog } from '../../stores/waitDialog';
import delay from '../../utils/delay';
import hex2rgba from '../../utils/hex2rgba';
import { fitToFeatures, highlightFeature } from '../../utils/mapView';
import { toWGS84 } from '../../utils/webmercator';
import {
  deleteCommuneLieuDit,
  getCommuneInfosFromXY,
  getContourCommunesInExtent,
  postCommuneLieuDit,
  updateAncienInsee,
} from './api';
import { createBackendEntry, deleteBackendEntry } from './bindings/backend-entry';
import { buildFeature } from './bindings/geometry-entry';
import DrawForm from './components/DrawForm';
import LinkForm from './components/LinkForm';
import { style } from './style';

const id = nanoid();
const props = {
  title: 'Lieu-dit',
  type: 'VectorImage',
  imageRatio: 2,
  visible: false,
  defaultGeomColor: '#ee9900',
  highlightColor: '#005ca9',
  associeColor: '#009ca9',
  toAssociateColor: '#e633ff',
  color: '#bac9a9', // Pour changer la couleur liée à la feature
  group: 'Commune',
};

let unsubViewStore;

const refresh = async () => {
  await show(getViewExtent());
};

// public methods
const show = async (extent) => {
  //removeSearchButton(id);
  enableAreaSearch(id, 'Rechercher les lieux-dits dans cette zone', show);

  if (getViewZoom() > 10) {
    let notif = addNotification({
      message: props.title + ' - ' + 'Chargement en cours',
      variant: 'loading',
      persist: true,
    });
    try {
      await _search({
        extent,
      });
      addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
    } finally {
      closeNotification(notif);
    }
  } else {
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });
    setVectorLayer({ id, style, source: new VectorSource(), visible: true });
  }

  if (unsubViewStore) {
    unsubViewStore(); // unsubscribe listeners
    unsubViewStore = undefined; // reset
  }
};

const hide = () => {
  disableAreaSearch(id);
  removeAllInteractions(id);

  const source = new VectorSource();
  if (unsubViewStore) {
    unsubViewStore(); // unsubscribe listeners
    unsubViewStore = undefined; // reset
  }
  setVectorLayer({ id, source, visible: false });
};

const _search = async ({ extent, setExtent }) => {
  const { type, features } = await getContourCommunesInExtent({ extent });
  let communesFeatures = new GeoJSON().readFeatures({ type, features });

  const source = new VectorSource({
    features: communesFeatures,
  });

  if (setExtent) {
    fitToFeatures(communesFeatures);
  }

  setVectorLayer({ id, source, style, visible: true });

  return true;
};

const popup = (props) => {
  return (
    <div>
      <div style={{ fontSize: '14px' }}>
        <strong>
          Nom: {props.nom}{' '}
          {props.nature == 'COMD' || props.nature == 'COMA'
            ? ''
            : `(${props?.insee_com || 'inconnu'})`}
          <br />
          {props.lieudit ? `Lieu-dit: ${props.lieudit}` : ''}
        </strong>
      </div>
    </div>
  );
};

const isValidFeatureInProgress = ({ feature }) => {
  let isOverlaps = false;
  const geojsonReader = new GeoJSONReader();
  const format = new GeoJSON({ dataProjection: 'EPSG:3857' });
  const geom1 = geojsonReader.read(format.writeGeometryObject(feature.getGeometry()));

  if (useLayerStore.getState().activeLayer.get('id') == id) {
    const source = useLayerStore.getState().activeLayer.getSource();
    const items = useItemsStore.getState().items;

    source.getFeatures().map((f) => {
      const geom2 = geojsonReader.read(format.writeGeometryObject(f.getGeometry()));

      // https://github.com/bjornharrtell/jsts/blob/master/src/org/locationtech/jts/monkey.js
      if (RelateOp.overlaps(geom1, geom2)) {
        isOverlaps = true;
        return;
      }
    });

    if (isOverlaps) {
      // permet de mettre à jour le formulaire de dessin
      items.props.communeProps = {
        ...items.props.communeProps,
        error:
          'Le lieu-dit dessiné chevauche un autre contour. Veuillez le modifier pour confirmer.',
      };
    } else {
      // eslint-disable-next-line
      const { error, ...noError } = items.props.communeProps;
      items.props.communeProps = { ...noError };
    }

    setItems(items);
  }

  return !isOverlaps;
};

const menuItems = () => [
  {
    key: nanoid(),
    text: 'Dessiner un lieu-dit',
    icon: 'LocationSearching',
    handleClick: async () => {
      const center = getCenter(getViewExtent());
      const centroid = toWGS84(center[0], center[1]);
      const { code, nom } = await getCommuneInfosFromXY(centroid);

      addItems({
        component: DrawForm,
        props: {
          communeProps: { centroid, insee_com: code, nom },
        },
        children: null,
      });

      addNotification({
        message: 'Dessinez le lieu-dit sur la carte',
        variant: 'default',
      });

      const intDrawId = nanoid();
      const intSnapId = nanoid();

      unsubViewStore = useViewStore.subscribe(
        // Permet de changer le centroid de la commune lorsque on navigue sur la carte avant de dessiner
        (state) => state.view.center,
        async (center) => {
          const centroid = toWGS84(center[0], center[1]);
          const { code, nom } = await getCommuneInfosFromXY(centroid);
          const items = useItemsStore.getState().items;
          items.props.communeProps = {
            ...items.props.communeProps,
            centroid,
            insee_com: code,
            nom,
          };
          setItems(items);
        }
      );

      addInteraction({
        id: intDrawId,
        type: 'Draw',
        layerId: id,
        geomType: 'MultiPolygon',
        trace: true,
        handleDrawend: async (e) => {
          unsubViewStore(); // unsubscribe listeners
          unsubViewStore = undefined; // reset
          removeInteraction({ id: intDrawId });
          removeInteraction({ id: intSnapId });

          let notifId = null;
          const f = e.feature;
          const intModifyId = nanoid();
          const interaction = useInteractionsStore
            .getState()
            .interactions.find((item) => item.id === intModifyId);

          if (!interaction) {
            const notifActions = [
              {
                title: 'Confirmer',
                handleClick: () => {
                  removeInteraction({ id: intModifyId });
                  const items = useItemsStore.getState().items;
                  const source = useLayerStore.getState().activeLayer.getSource();

                  addItems({
                    component: LinkForm,
                    props: {
                      onSubmit: async (data) => {
                        try {
                          const geojsonFeature = buildFeature({ feature: f, data });
                          const backendEntry = createBackendEntry({
                            data,
                            feature: geojsonFeature,
                          });

                          await postCommuneLieuDit(backendEntry);
                          await delay(1000 * 5);
                          addNotification({
                            message: 'Lieu-dit créé',
                            variant: 'success',
                          });
                          refresh();
                          return Promise.resolve();
                        } catch (err) {
                          refresh();
                          return Promise.reject(err);
                        }
                      },
                      communeProps: items.props.communeProps,
                      features: source.getFeatures(),
                    },
                    children: null,
                  });
                },
              },
              {
                title: 'Annuler',
                handleClick: () => {
                  removeInteraction({ id: intModifyId });
                  refresh();
                },
              },
            ];

            const isValid = isValidFeatureInProgress({ feature: f });
            if (isValid) {
              notifId = addNotification({
                message: 'Confirmer le contour du lieu-dit',
                variant: 'info',
                persist: true,
                actions: notifActions,
              });
            }

            addInteraction({
              id: intModifyId,
              type: 'Modify',
              features: new Collection([f]),
              handleModifyend: () => {
                const isValid = isValidFeatureInProgress({ feature: f });

                if (isValid && !notifId) {
                  notifId = addNotification({
                    message: 'Confirmer le contour du lieu-dit',
                    variant: 'info',
                    persist: true,
                    actions: notifActions,
                  });
                }

                if (!isValid && notifId) {
                  closeNotification(notifId);
                  notifId = null;
                }
              },
            });
          }
        },
      });

      addInteraction({
        id: intSnapId,
        type: 'Snap',
        layerId: id,
      });
    },
  },
]; // icons list: https://mui.com/components/material-icons/

const mapItem = (feature) => {
  // add, update or remove feature's properties here
  // in order to use them in listItem()
  // feature.set('mynewattribute', 'whatever');
  feature.set('nanoid', nanoid(), true);

  const geom = feature.get('geometry');
  const center = getCenter(geom.getExtent());
  const centroid = toWGS84(center[0], center[1]);

  feature.set('centroid', centroid, true);
  feature.set('area', (getArea(geom) / 10000).toFixed(2), true);
  return feature;
};

// this method has 2 parts: populate feature's actions array and return feature's display infos
const listItem = (feature) => {
  // use nanoid prop for an id
  const fProps = feature.getProperties();

  // default action is always center
  const actions = [
    {
      icon: 'CenterFocusStrong',
      title: 'Centrer',
      handleClick: () => {
        if (isMobile) {
          toggleSideBar(false);
        }
        highlightFeature(id, feature);
      },
    },
  ];

  if (fProps.lieudit) {
    actions.push({
      icon: 'Delete',
      title: "Supprimer l'assocation du lieu-dit",
      handleClick: async () => {
        try {
          const backendEntry = deleteBackendEntry({
            data: fProps,
          });
          setWaitDialog(true);
          await deleteCommuneLieuDit(backendEntry);

          addNotification({
            message: 'Assocation du lieu-dit supprimée',
            variant: 'success',
          });

          refresh();
        } catch (err) {
          console.log(err);
        } finally {
          setWaitDialog(false);
        }
      },
    });
  }

  if (!fProps.lieudit && !fProps.siren_epci) {
    actions.push({
      icon: 'AddLocation',
      title: 'Associer un lieu-dit',
      handleClick: () => {
        const source = useLayerStore.getState().activeLayer.getSource();

        addItems({
          component: LinkForm,
          props: {
            onSubmit: async (data) => {
              try {
                const backendEntry = createBackendEntry({
                  data,
                });
                await postCommuneLieuDit(backendEntry);
                await updateAncienInsee({
                  codeinsee: backendEntry.insee,
                  lieudit: backendEntry.lieudit,
                  ancieninsee: backendEntry.ancieninsee,
                });
                addNotification({
                  message: 'Lieu-dit associé',
                  variant: 'success',
                });
                refresh();
                return Promise.resolve();
              } catch (err) {
                return Promise.reject(err);
              }
            },
            communeProps: fProps,
            features: source.getFeatures(),
          },
          children: null,
        });
      },
    });
  }

  let color =
    fProps.nature == 'COMD' || fProps.nature == 'COMA'
      ? props.associeColor
      : props.defaultGeomColor;

  let avatar = (
    <Avatar
      sx={{
        backgroundColor: hex2rgba(color, 0.5),
        border: '2px solid ' + hex2rgba(color, 1.0),
        color: '#111',
      }}
      variant="rounded"
    >
      {''}
    </Avatar>
  );

  const area = (getArea(fProps.geometry) / 10000).toFixed(2);

  return {
    itemId: fProps.nanoid,
    titleHeader: `${fProps.nom} (${fProps.insee_com})`,
    subHeader: fProps.nature == 'COMD' || fProps.nature == 'COMA' ? 'Commune associée' : '',
    avatar: avatar,
    content: [
      fProps.lieudit ? `Lieu-dit: ${fProps.lieudit}` : '',
      'Surface (calculée): ' + area + ' ha',
    ],
    actions,
  };
};

export { hide, id, listItem, mapItem, menuItems, popup, props, show };
