import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const SECTEUR_DONNEE_COMMUNE = 'commune';
const SECTEUR_DONNEE_SECTEUR = 'secteur';
const SECTEUR_DONNEE_TECHNICIEN = 'technicien';
const SECTEUR_DONNEE_USINE = 'usine';

const useSecteurStore = create(
  subscribeWithSelector(() => ({
    donnee: SECTEUR_DONNEE_COMMUNE,
    secteurs: [],
    secteursColor: [],
    selected: '',
    techniciens: [],
    data: null,
    previewHistory: [],
  }))
);

const setSecteurDonnee = (donnee) => {
  useSecteurStore.setState(() => ({ donnee }));
};

const setSecteurSelected = (secteur) => {
  useSecteurStore.setState(() => ({ selected: secteur }));
};

export {
  useSecteurStore,
  setSecteurDonnee,
  setSecteurSelected,
  SECTEUR_DONNEE_COMMUNE,
  SECTEUR_DONNEE_SECTEUR,
  SECTEUR_DONNEE_TECHNICIEN,
  SECTEUR_DONNEE_USINE,
};
