import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import chroma from 'chroma-js';
import geojsonvt from 'geojson-vt';
import { nanoid } from 'nanoid';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import React from 'react';
import MaterialIcon from '../../components/MaterialIcon';
import { hideAlertDialog, openAlertDialog } from '../../stores/alertDialog';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import { useMapStore } from '../../stores/map';
import { useItemsStore } from '../../stores/menuItems';
import { addNotification, closeNotification } from '../../stores/notifications';
import {
  SECTEUR_DONNEE_COMMUNE,
  SECTEUR_DONNEE_SECTEUR,
  SECTEUR_DONNEE_TECHNICIEN,
  SECTEUR_DONNEE_USINE,
  setSecteurDonnee,
  useSecteurStore,
} from '../../stores/secteur';
import {
  removeTimeline,
  setTimelineVisible,
  showTimeline,
  useTimelineStore,
} from '../../stores/timeline';
import { getViewCenter, useViewStore } from '../../stores/view';
import { toDecimalString } from '../../utils/number';
import { vectorTileSourceFromGeojsonVt } from '../../utils/vectortile';
import * as features from '../index';
import { getContourCommunesSecteur, getSurfaceCommunes } from './api';
import ChangeSecteur from './components/ChangeSecteur';
import CommuneInfo from './components/CommuneInfo';
import { selectionStyle, style, styleCineticOrdre } from './style';
import * as XLSX from 'xlsx';

const id = nanoid();
const props = {
  title: 'Secteur',
  type: 'VectorTile',
  visible: false,
  color: '#696969',
  highlightColor: '#005ca9',
  opacity: 0.85,
  background: 'rgba(0, 0, 0, 0.35)',
  group: 'Commune',
  separator: '_',
};

// This second layer is used for VectorTile selection
// https://openlayers.org/en/latest/examples/vector-tile-selection.html
const idSelection = nanoid();
const propsSelection = {
  title: 'Secteur sélection',
  type: 'VectorTile',
  //renderMode: 'vector',
  visible: false,
  parentId: id,
  opacity: 0.2,
};

const idCineticOrdre = nanoid();
const propsCineticOrdre = {
  title: 'Secteur cinetic ordre',
  type: 'Vector',
  visible: false,
  parentId: id,
};

let highlightItemUnsub = null;
let cineticOrdreUnsub = null;
let timelineValuesUnsub = null;
let secteurDonneeUnsub = null;
let geojsonVtTileIndex = null;
let geoData = null;
let secteurSelected = '';

const tileSize = 1024;

// public methods
const show = async (forceUpdate) => {
  features['usine'].show();

  const notif = addNotification({
    message: props.title + ' - ' + 'Chargement en cours',
    variant: 'loading',
    persist: true,
  });

  try {
    // Get FeatureCollection
    if (!geoData) {
      geoData = await getContourCommunesSecteur();
      useSecteurStore.setState({ secteurs: [...geoData.secteurs.sort()] });

      useViewStore.getState().setCenter(getViewCenter());
      useViewStore.getState().setZoom(8);
    }

    // Get business data
    if (!useSecteurStore.getState().data) {
      const res = await getSurfaceCommunes('');
      const data = {
        ...res,
        dscommune: res.dscommune.filter((d) => parseInt(d.surfaceEnqueteBetterave) > 0),
        dslieudit: res.dslieudit.filter((d) => parseInt(d.surfaceEnqueteBetterave) > 0),
      };

      useSecteurStore.setState({ data });

      const techniciens = [
        ...new Set(res.dscommune.filter((d) => d.technicien !== '').map((d) => d.technicien)),
      ];

      useSecteurStore.setState({ techniciens: [...techniciens.sort()] });
    }

    useSecteurStore.getState().data.dslieudit.map((d) => {
      addFeatureProperties(d);
    });

    // Remove commune with surfaceEnqueteBetterave == 0 from FeatureCollection
    geoData = {
      ...geoData,
      features: geoData.features.filter((f) => f.properties.surfaceEnqueteBetterave > 0),
    };

    // Generate secteurs color's
    if (useSecteurStore.getState().secteursColor.length == 0) {
      // W3C X11 colors
      const uslColorList = {
        '0102': 'purple',
        '0103': 'orange',
        '0107': 'sienna',
        '0108': 'green',
        '0109': 'cyan',
        '0130': 'blue',
        '0131': 'yellow',
        '0138': 'red',
      };

      // Init couleur sans secteur ('ZZ0') par usl
      const uslList = useSecteurStore
        .getState()
        .data.dscommune.map((d) => d.usl)
        .reduce((acc, usl) => {
          if (acc.indexOf(usl) === -1) {
            acc.push(usl);
          }

          return acc;
        }, []);

      for (const usl of uslList) {
        useSecteurStore.getState().secteursColor[usl + props.separator + 'ZZ0'] = chroma(
          props.color
        );
      }

      // Generate usl secteur's luminance color
      // key = usl
      // value = usl color
      for (const [key, value] of Object.entries(uslColorList)) {
        const uslSecteurCount = useSecteurStore
          .getState()
          .data.dscommune.filter((d) => d.usl === key)
          // .filter((d) => d.surfaceEnqueteBetterave > 0)
          .reduce((acc, obj) => {
            if (obj.secteur === '') {
              obj.secteur = 'ZZ0';
            }

            if (acc.indexOf(obj.secteur) === -1) {
              acc.push(obj.secteur);
            }

            return acc;
          }, []).length;

        const luminanceStep = 0.9 / uslSecteurCount;

        useSecteurStore
          .getState()
          .data.dscommune.filter((d) => d.usl === key)
          // .filter((d) => d.surfaceEnqueteBetterave > 0)
          .reduce((acc, obj) => {
            if (obj.secteur === '') {
              obj.secteur = 'ZZ0';
            }

            if (!useSecteurStore.getState().secteursColor[key + props.separator + obj.secteur]) {
              acc += luminanceStep;
              useSecteurStore.getState().secteursColor[key + props.separator + obj.secteur] =
                chroma(value).luminance(acc);
            }

            return acc;
          }, 0);
      }
    }

    const source = _generateSource();

    setVectorLayer({ id, style, source: source, visible: true });
    setVectorLayer({
      id: idSelection,
      style: selectionStyle,
      source: source,
      visible: false,
    });

    if (!cineticOrdreUnsub) {
      cineticOrdreUnsub = useSecteurStore.subscribe(
        (state) => state.selected,
        (selected) => {
          if (secteurSelected !== selected) {
            onSecteurSwitchChange(false);
          }
          secteurSelected = selected;
        }
      );
    }

    if (!timelineValuesUnsub) {
      timelineValuesUnsub = useTimelineStore.subscribe(
        (state) => state.valuesForLayerId,
        (valuesForLayerId) => {
          if (useLayerStore.getState().activeLayer.get('id') == id) {
            const bounds = valuesForLayerId[id];
            onSecteurSwitchChange(true, bounds);
          }
        }
      );
    }

    // Setup highlightItem observer to display selection
    if (!highlightItemUnsub || forceUpdate) {
      if (highlightItemUnsub) {
        highlightItemUnsub();
      }

      highlightItemUnsub = useItemsStore.subscribe(
        (state) => state.highlightItem,
        (highlightItem) => {
          const selectedFeature = highlightItem?.feature;
          const layer = highlightItem?.layer;
          setVectorLayer({
            id: idSelection,
            visible: selectedFeature && layer && layer.get('id') == id,
          });

          const donnee = useSecteurStore.getState().donnee;
          if (donnee === SECTEUR_DONNEE_TECHNICIEN) {
            setVectorLayer({ id, style, source: source, visible: true });
          }
        }
      );
    }

    // Use for update features style in order to show or hide RRC communes
    if (!secteurDonneeUnsub || forceUpdate) {
      if (secteurDonneeUnsub) {
        secteurDonneeUnsub();
      }

      secteurDonneeUnsub = useSecteurStore.subscribe(
        (state) => state.donnee,
        // eslint-disable-next-line no-unused-vars
        (donnee) => {
          setVectorLayer({ id, style, source: source, visible: true });
        }
      );
    }

    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  } finally {
    closeNotification(notif);
  }
};

const _generateSource = () => {
  // Generate tiles
  geojsonVtTileIndex = geojsonvt(geoData, {
    extent: tileSize,
    debug: 0,
    tolerance: 2, // default 3 (2 pour avoir une meilleure définition des contours quand on dezoom)
    buffer: 16, // default 64 (64 pour tuile de 4096 donc 16 pour tuile de 1024)
    generateId: true,
  });

  // Update layer
  const projection = useMapStore.getState().map.getView().getProjection();
  return vectorTileSourceFromGeojsonVt(geojsonVtTileIndex, projection, tileSize);
};

const hide = () => {
  features['usine'].hide();

  if (highlightItemUnsub) {
    highlightItemUnsub();
    highlightItemUnsub = null;
  }

  if (cineticOrdreUnsub) {
    cineticOrdreUnsub();
    cineticOrdreUnsub = null;
  }

  removeTimeline(id);
  if (timelineValuesUnsub) {
    timelineValuesUnsub();
    timelineValuesUnsub = null;
  }

  if (secteurDonneeUnsub) {
    secteurDonneeUnsub();
    secteurDonneeUnsub = null;
  }

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
  setVectorLayer({ id: idSelection, source, visible: false });
  setVectorLayer({ id: idCineticOrdre, source, visible: false });
};

const popup = (props) => {
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <strong>
          {props.nom} ({props.insee_com})
          <br />
          USL: {props.usl}
          <br />
          USG: {props.usg}
          <br />
          Secteur: {props.secteur !== '' ? `${props.secteur}` : ''}
          <br />
          Technicien: {props.technicien !== '' ? `${props.technicien}` : ''}
          <br />
          Surf. (enq. bett.): {parseFloat(toDecimalString(props.surfaceEnqueteBetterave, 2))} Ha
          <br />
          Nb planteurs: {parseInt(props.nbPlanteur)}
        </strong>
      </div>
      <br />
      <Button
        onClick={() => {
          setSecteurDonnee(SECTEUR_DONNEE_USINE);
          openAlertDialog(
            '',
            <ChangeSecteur
              insee_com={props.insee_com}
              lieudit={props.lieudit}
              secteur={props.secteur}
              technicien={props.technicien}
              onPreview={onPreview}
              onDismiss={hideAlertDialog}
            />,
            []
          );
        }}
      >
        <MaterialIcon icon={'Edit'} />
      </Button>
    </>
  );
};

const highlightedItemComponent = (feature) => {
  return (
    <CommuneInfo
      selected={feature}
      onSecteurSwitchChange={onSecteurSwitchChange}
      onGeneratePreview={_generatePreviewFile}
    />
  );
};

const _generatePreviewFile = () => {
  const preview = useSecteurStore.getState().previewHistory;

  // generate worksheet and workbook
  const worksheet = XLSX.utils.json_to_sheet(preview);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Modifications secteurs');

  // fix headers
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [['Code INSEE', 'Lieu dit', 'Ancien secteur', 'Ancien RRC', 'Nouveau secteur', 'Nouveau RRC']],
    { origin: 'A1' }
  );

  XLSX.writeFile(workbook, 'Secteurs.xlsx', { compression: true });
};

const _updatePreviewHistory = (ds, secteur, technicien) => {
  const preview = useSecteurStore.getState().previewHistory;
  const exists = preview.find(
    (p) =>
      p.codeinsee === ds.codeinsee &&
      p.lieudit === ds.lieudit &&
      p.oldSecteur === secteur &&
      p.oldRRC === technicien
  );

  if (exists) {
    // remove from history
    const idx = preview.findIndex(
      (p) => p.codeinsee === exists.codeinsee && p.lieudit === exists.lieudit
    );

    if (idx !== -1) {
      useSecteurStore.setState({
        previewHistory: [...preview.slice(idx, 1)],
      });
    }
  } else {
    useSecteurStore.setState({
      previewHistory: [
        ...preview,
        {
          codeinsee: ds.codeinsee,
          lieudit: ds.lieudit,
          oldSecteur: ds.secteur,
          oldRRC: ds.technicien,
          newSecteur: secteur,
          newRRC: technicien,
        },
      ],
    });
  }
};

const onPreview = (commune, lieudit, secteur, technicien) => {
  const updatedFeat = geoData.features.map((f) =>
    f.properties.insee_com === commune && f.properties.lieudit === lieudit
      ? { ...f, properties: { ...f.properties, secteur: secteur, technicien: technicien } }
      : f
  );
  geoData = { ...geoData, features: updatedFeat }; // Update geo data

  const updatedDataDscommune = useSecteurStore
    .getState()
    .data.dscommune.map((d) =>
      d.codeinsee === commune ? { ...d, secteur: secteur, technicien: technicien } : d
    );

  const updatedDataDslieudit = useSecteurStore.getState().data.dslieudit.map((d) => {
    if (d.codeinsee === commune && d.lieudit === lieudit) {
      _updatePreviewHistory(d, secteur, technicien);

      return { ...d, secteur: secteur, technicien: technicien };
    } else {
      return d;
    }

    // d.codeinsee === commune && d.lieudit === lieudit
    //   ? { ...d, secteur: secteur, technicien: technicien }
    //   : d;
  });

  useSecteurStore.setState({
    data: {
      ...useSecteurStore.getState().data,
      dscommune: updatedDataDscommune,
      dslieudit: updatedDataDslieudit,
    },
  }); // Update business data

  // Update highlightedItem data
  const updatedHighlightFeature = useItemsStore.getState().highlightItem.feature;
  updatedHighlightFeature.set('secteur', secteur);
  updatedHighlightFeature.set('technicien', technicien);
  useItemsStore.setState({ highlightItem: { feature: updatedHighlightFeature } });

  show(true);
};

const onSecteurSwitchChange = (checked, bounds) => {
  if (checked) {
    const source = new VectorSource();
    const coordinates = geoData.features
      .filter((f) => f.properties.secteur === secteurSelected)
      .filter((f) => f.properties.surfaceEnqueteBetterave > 0)
      .sort(
        (a, b) =>
          a.properties.groupe - b.properties.groupe || a.properties.ordre - b.properties.ordre
      )
      .map((f) => f.properties.centroid.coordinates);

    if (bounds) {
      source.addFeatures([
        new Feature({
          geometry: new LineString(coordinates.slice(bounds[0], bounds[1] + 1)).transform(
            'EPSG:4326',
            'EPSG:3857'
          ),
        }),
      ]);
    } else {
      source.addFeatures([
        new Feature({
          geometry: new LineString(coordinates).transform('EPSG:4326', 'EPSG:3857'),
        }),
      ]);
    }

    if (!useTimelineStore.getState().visible) {
      const labels = geoData.features
        .filter((f) => f.properties.secteur === secteurSelected)
        .sort(
          (a, b) =>
            a.properties.groupe - b.properties.groupe || a.properties.ordre - b.properties.ordre
        )
        .map((f) => `${f.properties.secteur} - ${f.properties.groupe}/${f.properties.ordre}`);

      setTimelineVisible(true);
      showTimeline(id, 0, labels.length - 1, true, labels);
    }

    setVectorLayer({
      id: idCineticOrdre,
      style: styleCineticOrdre,
      source: source,
      visible: true,
    });
  } else {
    setTimelineVisible(false);
    const source = new VectorSource();
    setVectorLayer({ id: idCineticOrdre, source, visible: false });
  }
};

const addFeatureProperties = ({
  codeinsee,
  lieudit,
  usl,
  usg,
  nomusl,
  groupe,
  ordre,
  technicien,
  surfaceEnqueteBetterave,
  nbPlanteur,
}) => {
  const updatedFeat = geoData.features.map((f) =>
    f.properties.insee_com === codeinsee && f.properties.lieudit === lieudit
      ? {
          ...f,
          properties: {
            ...f.properties,
            usl,
            usg,
            nomusl,
            groupe: parseInt(groupe),
            ordre: parseInt(ordre),
            technicien,
            surfaceEnqueteBetterave: parseInt(surfaceEnqueteBetterave),
            nbPlanteur: parseInt(nbPlanteur),
          },
        }
      : f
  );
  geoData = { ...geoData, features: updatedFeat };
};

const menuItems = () => {
  const items = [];

  items.push({
    key: nanoid(),
    text: '',
    selectable: false,
    avatar: (
      <ToggleButtonGroup
        exclusive
        fullWidth
        sx={{ height: '35px' }}
        onChange={(event) => {
          setSecteurDonnee(event.target.value);
        }}
      >
        <ToggleButton value={SECTEUR_DONNEE_COMMUNE}>Commune</ToggleButton>
        <ToggleButton value={SECTEUR_DONNEE_SECTEUR}>Secteur</ToggleButton>
        <ToggleButton value={SECTEUR_DONNEE_TECHNICIEN}>RRC</ToggleButton>
        <ToggleButton value={SECTEUR_DONNEE_USINE}>Usine</ToggleButton>
      </ToggleButtonGroup>
    ),
  });

  return items;
};

export {
  hide,
  highlightedItemComponent,
  id,
  idCineticOrdre,
  idSelection,
  menuItems,
  popup,
  props,
  propsCineticOrdre,
  propsSelection,
  show,
};
