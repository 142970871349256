import React from 'react';
import { nanoid } from 'nanoid';
import delay from '../../utils/delay';
import {
  init as initCouvertMellifere,
  getPopupContent as getPopupContentCouvertMellifere,
  newParcelle as newParcelleCouvertMellifere,
  updateGeometryParcelle as updateGeometryCouvertMellifere,
  updateInfosParcelle as updateInfosCouvertMellifere,
  getColorCode,
} from './rules/couvert-mellifere';
import {
  getParcellesFromAPI,
  getCampagnes,
  sendParcelleToSIA,
  getAllCultures,
  deleteParcelleCouvertMellifereToAPI,
  updateParcelleToAPI,
  createParcelleToAPI,
  deleteParcelleToAPI,
  getParcellesPlanRecolte,
} from './api';
import { createOrUpdateBackendEntry, deleteBackendEntry } from './bindings/backend-entry';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import { useViewStore, getViewExtent, getViewZoom } from '../../stores/view';
import { addNotification, closeNotification } from '../../stores/notifications';
import {
  useInteractionsStore,
  addInteraction,
  removeInteraction,
  removeAllInteractions,
} from '../../stores/interactions';
import { addItems, useItemsStore, setItems } from '../../stores/menuItems';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import SearchForm from './components/SearchForm';
import CreateForm from './components/CreateForm';
import EditForm from './components/EditForm';
import DrawParcelleForm from './components/DrawParcelleForm';
import { style, styleCircuit } from './style';
import { parse, format } from 'date-fns';
import { format as numberFormat, isNumber } from '../../utils/number';
import { getCenter } from 'ol/extent';
import { getArea } from 'ol/sphere';
import Collection from 'ol/Collection';
import Cluster from 'ol/source/Cluster';
import { LineString, Point } from 'ol/geom';
import { toWGS84 } from '../../utils/webmercator';
import { natureProduitCode, typeBio, GeometryType, tableName } from './enums';
import { dsControleCheck } from '../../utils/dscontroleCheck';
import Hashes from 'jshashes/';
import { buildInsertFeature, buildUpdateFeature, computeCentroid } from './bindings/geometry-entry';
import { setWaitDialog } from '../../stores/waitDialog';
import { fitToFeatures, highlighLayer, highlightFeature } from '../../utils/mapView';
import { Avatar, MenuItem, Select, Switch } from '@mui/material';
import hex2rgba from '../../utils/hex2rgba';
import { disableAreaSearch, enableAreaSearch } from '../../stores/areaSearch';
import { isMobile } from 'react-device-detect';
import { toggleSideBar } from '../../stores/sidebar';
import {
  removeTimeline,
  setTimelineVisible,
  showTimeline,
  useTimelineStore,
} from '../../stores/timeline';
import { Feature } from 'ol';
import PlanRecolteForm from './components/PlanRecolteForm';

const id = nanoid();
const props = {
  title: 'Parcelle',
  type: 'VectorImage',
  imageRatio: 2,
  visible: false,
  defaultGeomColor: '#ee9900',
  highlightColor: '#005ca9',
  color: '#bac9a9', // Pour changer la couleur liée à la feature
  quickAccess: true,
  group: 'Campagne',
};

const idCircuit = nanoid();
const propsCircuit = {
  title: 'Parcelle Circuit',
  type: 'Vector',
  visible: false,
  parentId: id,
};

let cultures = [];
let campagnesBetterave = [];
let campagnesLuzerne = [];
let campagnesVinasse = [];
let campagneActiveSur8 = null;
let parmculture = natureProduitCode.planteur; // default value
let unsubViewStore;
let searchData = null;
let currentFeatures = null;
let isSearchByPlanRecolte = false;
let showCircuitPlanRecolte = false;
let timelineValuesUnsub = null;

// private methods
const _getNatproFromCultureCode = (code) => {
  if (code === '') {
    return natureProduitCode.planteur;
  }

  if (cultures.length > 0) {
    const culture = cultures.filter((i) => i.code === code);

    if (culture.length > 0) {
      return culture[0].natpro;
    }
  }
  return '';
};

const getCurrentCampagne = (natpro) => {
  if (natpro == natureProduitCode.luzerne) {
    return campagnesLuzerne[0];
  }
  if (natpro == natureProduitCode.vinasse) {
    return campagnesVinasse[0];
  }
  return campagnesBetterave[0];
};

const refresh = async (setExtent) => {
  if (searchData != null) {
    let notif = addNotification({
      message: props.title + ' - ' + 'Chargement en cours',
      variant: 'loading',
      persist: true,
    });
    try {
      const { campagne, codeplanteur, commune, culture, codecommande, materiel, typemateriel } =
        searchData;
      const parmcampalp4 = campagne.substr(2, 2) + campagne.substr(6, 2);
      const parmcpcu = codeplanteur ? codeplanteur.padStart(7, '0') : '';
      const parmcommune = commune ? commune.codeInsee.substring(0, 5) : '';
      const parmcommande = codecommande ? codecommande.padStart(6, '0') : '';
      parmculture = culture;
      // const bbox = parmculture ? getViewExtent() : '';

      await _search({
        setExtent,
        params: {
          parmcampalp4,
          parmcpcu,
          parmcommune,
          parmculture,
          parmcommande,
          materiel,
          typemateriel,
        },
      });

      addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
    } finally {
      closeNotification(notif);
    }
  } else {
    await show(getViewExtent());
  }
};

const loadNomenclaturesIfNeeded = async () => {
  if (Object.keys(campagnesBetterave).length === 0) {
    // init campagnes array
    campagnesBetterave = await getCampagnes('ENQSUR');
    campagnesLuzerne = await getCampagnes('LUZERNE');
    campagnesVinasse = await getCampagnes('VINASSES');
  }

  if (Object.keys(cultures).length === 0) {
    // init cultures array
    cultures = await getAllCultures();
    cultures.forEach((culture) => {
      culture.lastCampagne = getCurrentCampagne(culture.natpro).campsur8;
    });
    initCouvertMellifere(cultures);
  }
};

const showPlanRecolte = async (typemateriel, materiel) => {
  await loadNomenclaturesIfNeeded();

  campagneActiveSur8 = campagnesLuzerne[0].campsur8;

  searchData = {
    typemateriel,
    materiel,
    campagne: campagneActiveSur8,
    planRecolte: true,
    culture: natureProduitCode.luzerne,
  };

  await refresh(true);

  if (useLayerStore.getState().activeLayer.get('id') != id) {
    highlighLayer(id);
  }
};

// public methods
const show = async (extent) => {
  await loadNomenclaturesIfNeeded();

  searchData = null;
  isSearchByPlanRecolte = false;

  hideTimeline();

  enableAreaSearch(id, 'Rechercher les parcelles dans cette zone', show);

  const minimumZoom = parmculture == natureProduitCode.vinasse ? 10 : 12;

  if (getViewZoom() > minimumZoom) {
    let notif = addNotification({
      message: props.title + ' - ' + 'Chargement en cours',
      variant: 'default',
      persist: true,
    });
    try {
      await _search({
        extent,
        params: {
          parmculture,
          parmcampalp4: campagneActiveSur8
            ? campagneActiveSur8.substr(2, 2) + campagneActiveSur8.substr(6, 2)
            : getCurrentCampagne(parmculture).campsur4,
        },
      });
      addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
    } finally {
      closeNotification(notif);
    }
  } else {
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });
    setVectorLayer({ id, style, source: new VectorSource(), visible: true });
  }

  // keep track of zoom level change
  // in order to switch couvert mellifere layer source (VectorSource or Cluster)
  if (parmculture === natureProduitCode.couvertMellifere && !unsubViewStore) {
    let isClustered = false;
    unsubViewStore = useViewStore.subscribe(
      (state) => state.view.zoom,
      (zoom) => {
        if (zoom < 14 && !isClustered) {
          isClustered = true;
          show(getViewExtent());
        }

        if (zoom > 14 && isClustered) {
          isClustered = false;
          show(getViewExtent());
        }
      }
    );
  } else if (parmculture !== natureProduitCode.couvertMellifere && unsubViewStore) {
    unsubViewStore(); // unsubscribe listeners
    unsubViewStore = undefined; // reset
  }
};

const hideTimeline = () => {
  if (showCircuitPlanRecolte) {
    currentFeatures.forEach((f) => {
      f.set('opacity', 1.0, true);
    });
    setTimelineVisible(false);
    showCircuitPlanRecolte = false;
    setVectorLayer({ id: idCircuit, source: new VectorSource(), visible: false });
  }
};

const hide = () => {
  searchData = null;
  isSearchByPlanRecolte = null;

  disableAreaSearch(id);
  removeAllInteractions(id);

  hideTimeline();
  removeTimeline(id);
  if (timelineValuesUnsub) {
    timelineValuesUnsub();
    timelineValuesUnsub = null;
  }

  const source = new VectorSource();
  parmculture = natureProduitCode.planteur; // reset
  if (parmculture !== natureProduitCode.couvertMellifere && unsubViewStore) {
    unsubViewStore(); // unsubscribe listeners
    unsubViewStore = undefined; // reset
  }
  setVectorLayer({ id, source, visible: false });
};

const _search = async ({ extent, params, setExtent }) => {
  hideTimeline();

  if (searchData) {
    disableAreaSearch(id);
  }

  isSearchByPlanRecolte = searchData && searchData.planRecolte;

  const response = isSearchByPlanRecolte
    ? await getParcellesPlanRecolte(params)
    : await getParcellesFromAPI({ extent, params });
  const { type, features, message } = response;
  const map = {};

  // Maximum bbox reached ?
  if (message == 'bbox maximum surface reached') {
    await delay(200); // to display the warning notification below the previous one (loading data)
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });
    return false;
  }

  // Remove duplicate features geometries (i.e parcelles_planteur + luzernes on same parcelle),
  // by using a map of SHA256 hashes of features geometries.
  // The order of features in array that come from API is always the same.
  // The parcelles_planteur features are always processed at last
  const deDupFeatures = features.filter((f) => {
    const geomStr = JSON.stringify(f.geometry);
    const geomHash = new Hashes.SHA256().hex(geomStr);

    if (!map[geomHash]) {
      map[geomHash] = 1;
      return true;
    }

    return false;
  });

  currentFeatures = new GeoJSON().readFeatures({ type, features: deDupFeatures });

  const source = new VectorSource({
    features: currentFeatures,
  });

  if (setExtent) {
    fitToFeatures(currentFeatures);
  }

  const cluster = new Cluster({
    geometryFunction: function (feature) {
      const geometry = feature.getGeometry();
      const type = geometry.getType();

      switch (type) {
        case GeometryType.POINT:
          return geometry;
        case GeometryType.LINE_STRING:
          return new Point(geometry.getCoordinateAt(0.5));
        case GeometryType.POLYGON:
          return geometry.getInteriorPoint();
        case GeometryType.MULTI_POLYGON:
          return feature.getGeometry().getPolygons()[0].getInteriorPoint();
      }
    },
    source,
  });

  if (parmculture === natureProduitCode.couvertMellifere && getViewZoom() < 14) {
    // activate couvert mellifere clustering only if zoom < 14
    setVectorLayer({ id, source: cluster, style, visible: true });
  } else {
    setVectorLayer({ id, source, style, visible: true });
  }

  if (!timelineValuesUnsub) {
    timelineValuesUnsub = useTimelineStore.subscribe(
      (state) => state.valuesForLayerId,
      (valuesForLayerId) => {
        const values = valuesForLayerId[id];
        if (useLayerStore.getState().activeLayer.get('id') == id) {
          updateTimelinePlanRecolte(values);
        }
      }
    );
  }

  return true;
};

const updateTimelinePlanRecolte = (bounds) => {
  const ordres = [];
  const coordinates = [];
  currentFeatures.forEach((f) => {
    const indexTimeline = ordres.length;
    const visible = !bounds || (indexTimeline >= bounds[0] && indexTimeline <= bounds[1]);
    f.set('opacity', visible ? 1.0 : 0.2, true);
    if (ordres.indexOf(f.get('ordre')) < 0) {
      ordres.push(f.get('ordre'));
    }
    if (visible) {
      coordinates.push(f.get('centroid'));
    }
  });

  if (!bounds) {
    showTimeline(id, 0, ordres.length - 1, true, ordres);
  }

  const source = new VectorSource();
  source.addFeatures([
    new Feature({
      geometry: new LineString(coordinates).transform('EPSG:4326', 'EPSG:3857'),
    }),
  ]);
  setVectorLayer({ id: idCircuit, visible: true, style: styleCircuit, source });
  setVectorLayer({ id });
};

const popup = (props) => {
  const culture = props.culture || props.code_cultu;
  const featCulture = cultures.filter((c) => c.code === culture);

  if (featCulture.length > 0) {
    switch (featCulture[0].natpro) {
      case natureProduitCode.couvertMellifere:
        return getPopupContentCouvertMellifere(props);

      default:
        break;
    }
  }

  if (culture == 'vinasse') {
    return (
      <div>
        <div style={{ fontSize: '14px' }}>
          <strong>Commande n°{props.num_cde}</strong>
        </div>
        <div style={{ fontSize: '14px' }}>
          Client: <strong>{props.nom_client_liv}</strong>
        </div>
        <div style={{ fontSize: '14px' }}>
          Statut: <strong>{props.lib_statut}</strong>
        </div>
      </div>
    );
  }

  if (!props.codeparcelle) {
    return (
      <div>
        <div style={{ fontSize: '14px' }}>
          {parmculture == natureProduitCode.planteur ? 'Dernière culture' : "Culture de l'année"}:{' '}
          <strong>inconnue</strong>
        </div>
      </div>
    );
  }

  let libCulture = 'inconnue';
  if (props.culture) {
    libCulture =
      props.libeculture +
      ' (' +
      props.campagne.substring(0, 4) +
      '/' +
      props.campagne.substring(4, 8) +
      ')';
  }

  return (
    <div>
      <div style={{ fontSize: '14px' }}>
        <strong>
          {props.nomplanteur} ({props.codeplanteur})
        </strong>
      </div>
      <div style={{ fontSize: '14px' }}>
        {props.nomparcelle} ({props.codeparcelle})
      </div>
      <div style={{ fontSize: '14px' }}>
        {parmculture == natureProduitCode.planteur ? 'Dernière culture' : "Culture de l'année"}:{' '}
        <strong>{libCulture}</strong>
      </div>
    </div>
  );
};

const drawingFeatureInProgress = async ({ feature }) => {
  const idF = nanoid();
  feature.setId(idF);
  feature.set('nomparcelle', 'En cours de création');
  feature.set('nomculture', 'En cours de création');
  feature.set('coulculture', ''); // Permet de faire fonctionner le style lors de la création
  const geom = feature.get('geometry');
  const center = getCenter(geom.getExtent());
  const centroid = toWGS84(center[0], center[1]);
  // permet de mettre à jour le formulaire de création
  const items = useItemsStore.getState().items;
  items.props.parcelleProps = { centroid: centroid, area: (getArea(geom) / 10000).toFixed(2) };
  setItems(items);
};

const menuItems = () => {
  const actions = [
    {
      key: nanoid(),
      text: 'Voir les parcelles planteurs',
      icon: 'Search',
      selected: parmculture == natureProduitCode.planteur,
      handleClick: () => {
        campagneActiveSur8 = null;
        parmculture = natureProduitCode.planteur;
        show(getViewExtent());
      },
    },
    {
      key: nanoid(),
      text: 'Voir les parcelles betteraves',
      icon: 'Search',
      selected: parmculture == natureProduitCode.betterave && searchData == null,
      handleClick: () => {
        campagneActiveSur8 = campagnesBetterave[0].campsur8;
        parmculture = natureProduitCode.betterave;
        show(getViewExtent());
      },
    },
  ];

  if (parmculture == natureProduitCode.betterave && searchData == null) {
    actions.push({
      key: nanoid(),
      text: '',
      selected: true,
      selectable: false,
      avatar: (
        <Select
          fullWidth={true}
          defaultValue={campagneActiveSur8 ?? campagnesBetterave[0].campsur8}
          sx={{
            height: '35px',
            marginLeft: '40px',
            //width: '250px',
          }}
          onChange={(event) => {
            campagneActiveSur8 = event.target.value;
            show(getViewExtent());
          }}
        >
          {campagnesBetterave.map((option) => (
            <MenuItem key={option.campsur8} value={option.campsur8}>
              Campagne {option.libcamp}
            </MenuItem>
          ))}
        </Select>
      ),
    });
  }

  actions.push({
    key: nanoid(),
    text: 'Voir les parcelles luzernes',
    icon: 'Search',
    selected: parmculture == natureProduitCode.luzerne && searchData == null,
    handleClick: () => {
      campagneActiveSur8 = campagnesLuzerne[0].campsur8;
      parmculture = natureProduitCode.luzerne;
      show(getViewExtent());
    },
  });

  if (parmculture == natureProduitCode.luzerne && searchData == null) {
    actions.push({
      key: nanoid(),
      text: '',
      selected: true,
      selectable: false,
      avatar: (
        <Select
          fullWidth={true}
          defaultValue={campagneActiveSur8 ?? campagnesLuzerne[0].campsur8}
          sx={{
            height: '35px',
            marginLeft: '40px',
            //width: '250px',
          }}
          onChange={(event) => {
            campagneActiveSur8 = event.target.value;
            show(getViewExtent());
          }}
        >
          {campagnesLuzerne.map((option) => (
            <MenuItem key={option.campsur8} value={option.campsur8}>
              Campagne {option.libcamp}
            </MenuItem>
          ))}
        </Select>
      ),
    });
  }

  actions.push({
    key: nanoid(),
    text: 'Voir les parcelles vinasses',
    icon: 'Search',
    selected: parmculture == natureProduitCode.vinasse && searchData == null,
    handleClick: () => {
      campagneActiveSur8 = campagnesVinasse[0].campsur8;
      parmculture = natureProduitCode.vinasse;
      show(getViewExtent());
    },
  });

  if (parmculture == natureProduitCode.vinasse && searchData == null) {
    actions.push({
      key: nanoid(),
      text: '',
      selected: true,
      selectable: false,
      avatar: (
        <Select
          fullWidth={true}
          defaultValue={campagneActiveSur8 ?? campagnesVinasse[0].campsur8}
          sx={{
            height: '35px',
            marginLeft: '40px',
            //width: '250px',
          }}
          onChange={(event) => {
            campagneActiveSur8 = event.target.value;
            show(getViewExtent());
          }}
        >
          {campagnesVinasse.map((option) => (
            <MenuItem key={option.campsur8} value={option.campsur8}>
              Campagne {option.libcamp}
            </MenuItem>
          ))}
        </Select>
      ),
    });
  }

  actions.push(
    {
      key: nanoid(),
      text: 'Voir les couverts méllifère',
      icon: 'Search',
      selected: parmculture == natureProduitCode.couvertMellifere,
      handleClick: () => {
        parmculture = natureProduitCode.couvertMellifere;
        show(getViewExtent());
      },
    },
    {
      key: nanoid(),
      text: 'Recherche avancée',
      subtitle: getSearchDataSubtitle(),
      icon: 'Search',
      selected: searchData != null && !isSearchByPlanRecolte,
      handleClick: () =>
        addItems({
          component: SearchForm,
          props: {
            campagnesBetterave,
            campagnesLuzerne,
            campagnesVinasse,
            onSubmit: async (data) => {
              campagneActiveSur8 = data.campagne ?? null;
              searchData = data;
              await refresh(true);

              return Promise.resolve();
            },
          },
          children: null,
        }),
    },
    {
      key: nanoid(),
      text: 'Plan de récolte luzerne',
      subtitle: getPlanRecolteSubtitle(),
      icon: 'Search',
      selected: searchData != null && isSearchByPlanRecolte,
      handleClick: async () => {
        addItems({
          component: PlanRecolteForm,
          props: {
            onSubmit: async (data) => {
              await showPlanRecolte(data.typemateriel, data.materiel);

              return Promise.resolve();
            },
          },
          children: null,
        });
      },
    }
  );

  if (isSearchByPlanRecolte) {
    actions.push({
      key: nanoid(),
      text: 'Afficher le circuit du plan de récolte',
      selectable: false,
      selected: false,
      icon: 'Route',
      avatar: <Switch checked={showCircuitPlanRecolte} />,
      handleClick: () => {
        if (showCircuitPlanRecolte) {
          hideTimeline();
          setVectorLayer({ id });
        } else {
          showCircuitPlanRecolte = true;
          setTimelineVisible(true);
          updateTimelinePlanRecolte();
        }
      },
    });
  }

  actions.push({
    key: nanoid(),
    text: 'Dessiner et déclarer',
    icon: 'LocationSearching',
    handleClick: () => {
      const center = getCenter(getViewExtent());
      const centroid = toWGS84(center[0], center[1]);
      addItems({
        component: DrawParcelleForm,
        props: {
          parcelleProps: { centroid },
        },
        children: null,
      });
      addNotification({
        message: 'Dessinez la parcelle sur la carte',
        variant: 'default',
      });
      const intId = nanoid();
      unsubViewStore = useViewStore.subscribe(
        // Permet de changer la commune de centroid lorsque on navigue sur la carte avant de dessiner
        (state) => state.view.center,
        (center) => {
          const centroid = toWGS84(center[0], center[1]);
          const items = useItemsStore.getState().items;
          items.props.parcelleProps = { centroid: centroid, area: 0 };
          setItems(items);
        }
      );
      addInteraction({
        id: intId,
        type: 'Draw',
        layerId: id,
        geomType: 'MultiPolygon',
        handleDrawend: async (e) => {
          unsubViewStore(); // unsubscribe listeners
          unsubViewStore = undefined; // reset
          removeInteraction({ id: intId });
          const f = e.feature;
          await drawingFeatureInProgress({ feature: f });
          const intModifyId = nanoid();
          const interaction = useInteractionsStore
            .getState()
            .interactions.find((item) => item.id === intModifyId);
          if (!interaction) {
            const notifActions = [
              {
                title: 'Confirmer',
                handleClick: async () => {
                  removeInteraction({ id: intModifyId });
                  const items = useItemsStore.getState().items;
                  addItems({
                    component: CreateForm,
                    props: {
                      onSubmit: async (data) => {
                        try {
                          const natpro = _getNatproFromCultureCode(
                            data.culture.code || data.culture
                          );
                          if (natpro === natureProduitCode.couvertMellifere) {
                            const response = await newParcelleCouvertMellifere(f, data);
                            if (response.gid) {
                              addNotification({
                                message: 'Parcelle créée',
                                variant: 'success',
                              });
                              parmculture = natureProduitCode.couvertMellifere;
                              refresh();
                              return Promise.resolve();
                            } else {
                              addNotification({
                                message: 'Parcelle NON créée',
                                variant: 'error',
                              });
                              return Promise.reject('something wrong');
                            }
                          } else {
                            // others cultures logic
                            const natpro = _getNatproFromCultureCode(
                              data.culture.code || data.culture
                            );
                            const geojsonFeature = buildInsertFeature(
                              f,
                              data,
                              natpro,
                              getCurrentCampagne(natpro).campsur8
                            );
                            await createParcelleToAPI(geojsonFeature);

                            data.longitudeparc = geojsonFeature.properties.longitudeparc;
                            data.latitudeparc = geojsonFeature.properties.latitudeparc;

                            const backendEntry = createOrUpdateBackendEntry({
                              data,
                              campagne: campagneActiveSur8 ?? getCurrentCampagne(natpro).campsur8,
                            });
                            const { dscontrole = [] } = await sendParcelleToSIA(backendEntry);

                            return dsControleCheck(dscontrole)
                              .then(async () => {
                                await delay(1000 * 5);
                                addNotification({
                                  message: 'Parcelle créée',
                                  variant: 'success',
                                });
                                refresh();
                                return Promise.resolve();
                              })
                              .catch(async (err) => {
                                let table = tableName[natpro];
                                if (natpro !== 'T') {
                                  table += '_' + getCurrentCampagne(natpro).campsur8;
                                }
                                // Impossible de créer la parcelle côté SIA, on supprime le contour qui vient d'être créé
                                await deleteParcelleToAPI(table, f.getId().substring(0, 9));
                                refresh();
                                return Promise.reject(err);
                              });
                          }
                        } catch (err) {
                          refresh();
                          return Promise.reject(err);
                        }
                      },
                      parcelleProps: items.props.parcelleProps,
                      canChangeCultureType: true,
                      isNewParcelle: true,
                      campagneAppairage: '',
                      culturesArray: cultures,
                    },
                    children: null,
                  });
                },
              },
              {
                title: 'Annuler',
                handleClick: () => {
                  removeInteraction({ id: intModifyId });
                  refresh();
                },
              },
            ];
            addNotification({
              message: 'Confirmer le contour de la parcelle',
              variant: 'info',
              persist: true,
              actions: notifActions,
            });
            addInteraction({
              id: intModifyId,
              type: 'Modify',
              features: new Collection([f]),
              handleModifyend: async () => {
                await drawingFeatureInProgress({ feature: f });
              },
            });
          }
        },
      });
    },
  });

  return actions;
}; // icons list: https://mui.com/components/material-icons/

const getSearchDataSubtitle = () => {
  if (searchData == null || isSearchByPlanRecolte) return null;
  const { campagne, codeplanteur, commune, libculture, codecommande } = searchData;
  let subtitles = [];
  if (codeplanteur) subtitles.push('Planteur ' + codeplanteur);
  if (commune) subtitles.push(commune.nomCommune);
  if (libculture) subtitles.push(libculture);
  if (campagne) subtitles.push('Campagne ' + campagne.substr(0, 4) + '/' + campagne.substr(4, 4));
  if (codecommande) subtitles.push('Commande ' + codecommande);
  return subtitles.join(' - ');
};

const getPlanRecolteSubtitle = () => {
  if (searchData == null || !isSearchByPlanRecolte) return null;
  return searchData.typemateriel + searchData.materiel;
};

const mapItem = (feature) => {
  // add, update or remove feature's properties here
  // in order to use them in listItem()
  // feature.set('mynewattribute', 'whatever');
  feature.set('nanoid', nanoid(), true);

  const geom = feature.get('geometry');
  const center = getCenter(geom.getExtent());
  const centroid = toWGS84(center[0], center[1]);

  feature.set('centroid', centroid, true);
  feature.set('area', (getArea(geom) / 10000).toFixed(2), true);
  return feature;
};

// this method has 2 parts: populate feature's actions array and return feature's display infos
const listItem = (feature) => {
  // use nanoid prop for an id
  const fProps = feature.getProperties();
  const natpro = _getNatproFromCultureCode(fProps.culture || fProps.code_cultu);

  // default action is always center
  const actions = [
    {
      icon: 'CenterFocusStrong',
      title: 'Centrer',
      handleClick: () => {
        if (isMobile) {
          toggleSideBar(false);
        }
        highlightFeature(id, feature);
      },
    },
  ];

  if (natpro === natureProduitCode.couvertMellifere) {
    actions.push({
      icon: 'Delete',
      title: 'Supprimer le contour',
      handleClick: async () => {
        try {
          setWaitDialog(true);
          await deleteParcelleCouvertMellifereToAPI(feature.getId());
          addNotification({
            message: 'Contour de couvert méllifère supprimé',
            variant: 'success',
          });
          parmculture = natureProduitCode.couvertMellifere;
          refresh();
        } catch (error) {
          console.log(error);
          addNotification({
            message: 'Impossible de supprimer le contour du couvert méllifère',
            variant: 'error',
          });
        } finally {
          setWaitDialog(false);
        }
      },
    });

    const intModifyId = nanoid();
    actions.push({
      icon: 'EditLocation',
      title: 'Modifier le contour',
      handleClick: () => {
        const interaction = useInteractionsStore
          .getState()
          .interactions.find((item) => item.id === intModifyId);
        if (!interaction) {
          const notifActions = [
            {
              title: 'Confirmer',
              handleClick: async () => {
                removeInteraction({ id: intModifyId });
                try {
                  setWaitDialog(true);
                  await updateGeometryCouvertMellifere(feature);
                  addNotification({
                    message: 'Contour de couvert méllifère modifié',
                    variant: 'success',
                  });
                  parmculture = natureProduitCode.couvertMellifere;
                  refresh();
                } catch (error) {
                  console.log(error);
                  addNotification({
                    message: 'Impossible de modifier le contour du couvert méllifère',
                    variant: 'error',
                  });
                } finally {
                  setWaitDialog(false);
                }
              },
            },
            {
              title: 'Annuler',
              handleClick: () => {
                removeInteraction({ id: intModifyId });
                refresh();
              },
            },
          ];

          addNotification({
            message: props.title + ' - ' + 'Modification du contour',
            variant: 'info',
            persist: true,
            actions: notifActions,
          });

          addInteraction({
            id: intModifyId,
            type: 'Modify',
            features: new Collection([feature]),
            handleModifyend: () => {},
          });
        }
      },
    });

    actions.push({
      icon: 'Edit',
      title: 'Modifier les infos',
      handleClick: () => {
        const {
          code_planteur: codeplanteur,
          nom_couvert: nomparcelle,
          code_cultu: culture,
          surf_reelle,
          centroid,
          area,
        } = fProps;
        const surfreelle = !surf_reelle
          ? ''
          : ('' + parseInt(surf_reelle)).padStart(3, '0') +
            ',' +
            (surf_reelle + '').split('.')[1].padEnd(2, '0');

        const props = { codeplanteur, nomparcelle, culture, surfreelle, centroid, area };

        addItems({
          component: EditForm,
          props: {
            onSubmit: async (data) => {
              try {
                await updateInfosCouvertMellifere(feature, data);
                addNotification({
                  message: 'Infos du couvert méllifère modifiées',
                  variant: 'success',
                });
                parmculture = natureProduitCode.couvertMellifere;
                refresh();
                return Promise.resolve();
              } catch (err) {
                addNotification({
                  message: 'Impossible de modifier les infos du couvert méllifère',
                  variant: 'error',
                });
                return Promise.reject(err);
              }
            },
            parcelleProps: props,
            canChangeCultureType: parmculture != natureProduitCode.planteur,
            culturesArray: cultures.filter((c) => c.natpro === natureProduitCode.couvertMellifere),
          },
          children: null,
        });
      },
    });
  } else if (fProps.culture != 'vinasse') {
    // others natpro
    // TODO refactor and separate logic in others files like couvert mellifere
    if (!isNumber(fProps.id_parcel) && (!fProps.codeplanteur || !fProps.codeparcelle)) {
      // parcelle dessinée non appairée, on propose la suppression du contour via parcelles-api
      actions.push({
        icon: 'Delete',
        title: 'Supprimer le contour',
        handleClick: async () => {
          try {
            const id_parcel = fProps.id_parcel;
            const tablename = feature.getId().substr(0, feature.getId().indexOf('.'));
            setWaitDialog(true);
            await deleteParcelleToAPI(tablename, id_parcel);

            addNotification({
              message: 'Contour de parcelle supprimée',
              variant: 'success',
            });
            refresh();
          } catch (err) {
            console.log(err);
          } finally {
            setWaitDialog(false);
          }
        },
      });
    }

    if (fProps.codeplanteur && fProps.codeparcelle) {
      // parcelle appairée
      const intModifyId = nanoid();
      actions.push({
        icon: 'EditLocation',
        title: 'Modifier le contour',
        handleClick: () => {
          const interaction = useInteractionsStore
            .getState()
            .interactions.find((item) => item.id === intModifyId);
          if (!interaction) {
            const notifActions = [
              {
                title: 'Confirmer',
                handleClick: async () => {
                  try {
                    setWaitDialog(true);
                    removeInteraction({ id: intModifyId });
                    const geojsonFeature = buildUpdateFeature(feature);
                    await updateParcelleToAPI(geojsonFeature);

                    const backendEntry = createOrUpdateBackendEntry({
                      data: {
                        ...fProps,
                        commune: fProps.nomcommunep,
                        codeinsee: fProps.communeparc,
                        numilot: fProps.numeroilot,
                        surfcalc: geojsonFeature.properties.surf_calc,
                        longitudeparc: geojsonFeature.properties.longitudeparc,
                        latitudeparc: geojsonFeature.properties.latitudeparc,
                        codemaj: 'M',
                      },
                      campagne: fProps.campagne,
                    });

                    const { dscontrole = [] } = await sendParcelleToSIA(backendEntry); // update parcelle centroid
                    await dsControleCheck(dscontrole); // return a resolved or rejected promise, rejected will be catched

                    addNotification({
                      message: 'Contour de parcelle modifié',
                      variant: 'success',
                    });
                  } catch (error) {
                    console.log(error);
                    addNotification({
                      message: 'Impossible de modifier le contour de parcelle',
                      variant: 'error',
                    });
                  } finally {
                    setWaitDialog(false);
                  }
                },
              },
              {
                title: 'Annuler',
                handleClick: () => {
                  removeInteraction({ id: intModifyId });
                  refresh();
                },
              },
            ];

            addNotification({
              message: props.title + ' - ' + 'Modification du contour',
              variant: 'info',
              persist: true,
              actions: notifActions,
            });

            addInteraction({
              id: intModifyId,
              type: 'Modify',
              features: new Collection([feature]),
              handleModifyend: () => {},
            });

            if (isMobile) {
              toggleSideBar(false);
            }
          }
        },
      });
      actions.push({
        icon: 'Edit',
        title: 'Modifier les infos',
        handleClick: () => {
          fProps.surfreelle = !fProps.surface
            ? ''
            : fProps.surface.substring(0, 3) + ',' + fProps.surface.substring(3, 5);
          const centroid = computeCentroid({ feature });

          addItems({
            component: EditForm,
            props: {
              onSubmit: async (data) => {
                try {
                  const natpro = _getNatproFromCultureCode(data.culture.code || data.culture);
                  const lastCampagneForCulture = getCurrentCampagne(natpro).campsur8;

                  // Si on travaille sur une culture, on prend la campagne sélectionnée sur cette culture pour mettre à jour la parcelle.
                  // Sinon on conserve la campagne de dernier appairage de la parcelle planteur.
                  // Et si jamais cette campagne est vide (on avait pas la dernière campagne d'appairage dans la table planteur avant)
                  // on prend la dernière campagne de la culture.
                  let campagne =
                    parmculture !== natureProduitCode.planteur
                      ? campagneActiveSur8
                      : fProps.campagne ?? lastCampagneForCulture;

                  const backendEntry = createOrUpdateBackendEntry({
                    data: {
                      ...data,
                      edit: true,
                      longitudeparc: Math.floor(centroid[0]).toString().padStart(8, '0'),
                      latitudeparc: Math.floor(centroid[1]).toString().padStart(8, '0'),
                    },
                    campagne: campagne,
                  });
                  const { dscontrole = [] } = await sendParcelleToSIA(backendEntry);
                  await dsControleCheck(dscontrole); // return a resolved or rejected promise, rejected will be catched

                  await delay(1000 * 5);

                  addNotification({
                    message: 'Infos parcelle modifiées',
                    variant: 'success',
                  });
                  refresh();
                  return Promise.resolve();
                } catch (err) {
                  return Promise.reject(err);
                }
              },
              parcelleProps: fProps,
              canChangeCultureType: parmculture != natureProduitCode.planteur,
              culturesArray: cultures.filter((c) => c.natpro == parmculture),
            },
            children: null,
          });
        },
      });
      actions.push({
        icon: 'Delete',
        title: !isNumber(fProps.id_parcel)
          ? 'Supprimer le contour'
          : parmculture == natureProduitCode.planteur
          ? 'Supprimer la déclaration'
          : 'Supprimer la déclaration ' + fProps.libeculture,
        handleClick: async () => {
          try {
            fProps.surfreelle = !fProps.surface
              ? ''
              : fProps.surface.substring(0, 3) + ',' + fProps.surface.substring(3, 5);
            const centroid = computeCentroid({ feature });

            // Si on supprime la déclaration depuis la couche planteur, on remet à vide la culture
            // pour forcer le désappairage de toutes les tables (planteur + cultures)
            if (parmculture == natureProduitCode.planteur) {
              fProps.culture = '';
            }

            const backendEntry = deleteBackendEntry({
              data: {
                ...fProps,
                longitudeparc: Math.floor(centroid[0]).toString().padStart(8, '0'),
                latitudeparc: Math.floor(centroid[1]).toString().padStart(8, '0'),
              },
            });

            setWaitDialog(true);
            const { dscontrole = [] } = await sendParcelleToSIA(backendEntry);
            await dsControleCheck(dscontrole); // return a resolved or rejected promise, rejected will be catched

            await delay(1000 * 5);

            addNotification({
              message: 'Déclaration de parcelle supprimée',
              variant: 'success',
            });
            refresh();
          } catch (err) {
            console.log(err);
          } finally {
            setWaitDialog(false);
          }
        },
      });
    } else {
      // parcelle anonyme
      actions.push({
        icon: 'AddLocation',
        title: 'Déclarer',
        handleClick: () => {
          addItems({
            component: CreateForm,
            props: {
              onSubmit: async (data) => {
                try {
                  const centroid = computeCentroid({ feature });

                  data.longitudeparc = Math.floor(centroid[0]).toString().padStart(8, '0');
                  data.latitudeparc = Math.floor(centroid[1]).toString().padStart(8, '0');

                  const backendEntry = createOrUpdateBackendEntry({
                    data,
                    campagne:
                      parmculture != natureProduitCode.planteur
                        ? campagneActiveSur8
                        : getCurrentCampagne(natureProduitCode.planteur).campsur8,
                  });
                  const { dscontrole = [], codparcelle = '' } = await sendParcelleToSIA(
                    backendEntry
                  );
                  await dsControleCheck(dscontrole); // return a resolved or rejected promise, rejected will be catched
                  feature.set('codeplanteur', data.nomplanteur.cpcu || data.selparcelle.cpcu);
                  feature.set('codeparcelle', codparcelle);

                  await delay(1000 * 5); // TODO avoid this hardcoded delay by using a PostGIS trigger

                  addNotification({
                    message: 'Parcelle déclarée',
                    variant: 'success',
                  });
                  refresh();
                  return Promise.resolve();
                } catch (err) {
                  return Promise.reject(err);
                }
              },
              parcelleProps: fProps,
              canChangeCultureType: parmculture != natureProduitCode.planteur,
              isNewParcelle: false,
              campagneAppairage: campagneActiveSur8,
              culturesArray: cultures.filter((c) => c.natpro == parmculture),
            },
            children: null,
          });
        },
      });
    }
  }

  // JR: 07/01/2025
  // En commentaire car on appelle plus l'API du SIA qui va chercher les conseils agricoles
  // let listPdfCag = [];
  // if (fProps.dspdfcag) {
  //   listPdfCag = fProps.dspdfcag
  //     .sort((a, b) => b.datconseil - a.datconseil)
  //     .map((i) => ({
  //       props: { sx: { mr: '50%', mb: 1 }, variant: 'outlined' },
  //       title: format(parse(i.datconseil, 'yyyyMMdd', new Date()), 'dd/MM/yyyy'),
  //       handleClick: () => {
  //         showDocument({
  //           server: i.serveur,
  //           path: i.chemin,
  //           name: i.nomdupdf,
  //         });
  //       },
  //     }));
  //   listPdfCag.unshift('PDF Conseils agricoles: ');
  // }

  const cultureCode = fProps.culture || fProps.code_cultu || '';
  let color = props.defaultGeomColor;
  if (cultureCode.includes('SC')) {
    color = getColorCode(cultureCode);
  } else if (fProps.coulculture) {
    color = fProps.coulculture;
  } else if (fProps.codeplanteur && fProps.codeparcelle) {
    color = props.color;
  }
  let avatar = (
    <Avatar
      sx={{
        backgroundColor: hex2rgba(color, 0.5),
        border: '2px solid ' + hex2rgba(color, 1.0),
        color: '#111',
      }}
      variant="rounded"
    >
      {fProps.codeparcelle ? fProps.codeparcelle : ' '}
    </Avatar>
  );

  if (natpro === natureProduitCode.couvertMellifere) {
    return {
      itemId: fProps.nanoid,
      titleHeader: `${fProps.code_planteur}`,
      subHeader: `${fProps.nom_couvert}`,
      avatar: avatar,
      content: [
        'Culture: ' + getPopupContentCouvertMellifere(fProps),
        'Surface parcelle (calculée): ' + fProps.surf_calc + ' ha',
        'Surface parcelle (réelle): ' + fProps.surf_reelle + ' ha',
      ],
      actions,
    };
  } else if (fProps.culture == 'vinasse') {
    const area = (getArea(fProps.geometry) / 10000).toFixed(2);

    let content = [
      'Commune: ' + fProps.nom_commune + ' (' + fProps.codeinsee + ')',
      'Planteur/Client: ' + fProps.nom_client_liv + ' (' + fProps.cod_client + ')',
      'Parcelle: ' +
        fProps.nom_parcelle +
        (fProps.cod_parcelle ? '(' + fProps.cod_parcelle + ')' : ''),
      'Epoque épandage: ' + fProps.lib_epoque,
    ];

    if (fProps.status >= '40') {
      let dateEpandage = '';
      if (fProps.date_epandage) {
        dateEpandage = format(parse(fProps.date_epandage, 'yyyyMMdd', new Date()), 'dd/MM/yyyy');
      }

      content.push(
        'Surface épandue: ' + numberFormat(fProps.surf_epandue, 'ha', 2),
        'Tonnage épandu: ' + numberFormat(fProps.tonnage_epandu, 'T', 3),
        'Dose épandue: ' + numberFormat(fProps.dose_epandue, 'T/ha', 2),
        'Date épandage: ' + dateEpandage
      );
    } else {
      content.push(
        'Surface à épandre: ' + numberFormat(fProps.surface, 'ha', 2),
        'Dose à épandre: ' + numberFormat(fProps.dose, 'T/ha', 2)
      );
    }

    content.push('Surface parcelle (calculée): ' + area + ' ha');

    return {
      itemId: fProps.nanoid,
      titleHeader: `Commande n°${fProps.num_cde}`,
      subHeader: `${fProps.nom_client_liv}`,
      avatar,
      content,
      actions,
    };
  } else {
    // parcelle anonyme
    const area = (getArea(fProps.geometry) / 10000).toFixed(2);

    // others natpro
    // TODO refactor and separate logic in others files like couvert mellifere
    if (!fProps.codeplanteur || !fProps.codeparcelle) {
      return {
        itemId: fProps.nanoid,
        titleHeader: 'Parcelle anonyme',
        subHeader: '',
        avatar: avatar,
        content: ['Surface parcelle (calculée): ' + area + ' ha'],
        actions,
      };
    } else {
      // parcelle appairée
      const bio = typeBio.find((i) => i.value === fProps.bio);

      return {
        itemId: fProps.nanoid,
        titleHeader: `${fProps.nomplanteur} (${fProps.codeplanteur})`,
        subHeader: `${fProps.nomparcelle} (${fProps.codeparcelle})`,
        avatar: avatar,
        content: [
          (parmculture == natureProduitCode.planteur
            ? 'Dernière culture: '
            : "Culture de l'année: ") +
            (fProps.culture
              ? (fProps.libeculture || fProps.culture) +
                ' (' +
                fProps.campagne.substring(0, 4) +
                '/' +
                fProps.campagne.substring(4, 8) +
                ')'
              : 'inconnue'),
          'Ilot: ' + (fProps.numeroilot ? fProps.numeroilot : ''),
          'Commune: ' +
            (fProps.communeparc
              ? fProps.nomcommunep + ' (' + fProps.communeparc?.substring(0, 2) + ')'
              : ''),
          'Commune emplacement silo: ' +
            (fProps.communeemps
              ? fProps.nomcommunee + ' (' + fProps.communeemps?.substring(0, 5) + ')'
              : ''),
          'Emplacement silo: ' + (fProps.emps ? fProps.nomemps + ' (' + fProps.emps + ')' : ''),
          'Planteur bio: ' + (bio ? bio?.label : fProps.bio),
          'Surface parcelle (réelle): ' +
            numberFormat(fProps.surface, fProps.surface_u, fProps.surface_f?.split(',')[1]),
          'Surface parcelle (calculée): ' + area + ' ha',
          //...listPdfCag,
        ],
        actions,
      };
    }
  }
};

export {
  id,
  props,
  idCircuit,
  propsCircuit,
  show,
  showPlanRecolte,
  hide,
  popup,
  mapItem,
  listItem,
  menuItems,
};
