import { Button, CircularProgress, Divider, Paper, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  SECTEUR_DONNEE_COMMUNE,
  SECTEUR_DONNEE_SECTEUR,
  SECTEUR_DONNEE_TECHNICIEN,
  SECTEUR_DONNEE_USINE,
  setSecteurSelected,
  useSecteurStore,
} from '../../../../stores/secteur';
import { toDecimalString } from '../../../../utils/number';

const CommuneInfo = ({ selected, onSecteurSwitchChange, onGeneratePreview }) => {
  let name = '';
  const donnee = useSecteurStore((state) => state.donnee);
  const { dscommune } = useSecteurStore((state) => state.data);

  const [prevSecteur, setPrevSecteur] = useState(
    <CircularProgress color="primary" thickness={6} size={24} />
  );
  const [prevCommune, setPrevCommune] = useState('');
  const [prevDonnee, setPrevDonnee] = useState(donnee);
  const [prevDscommune, setPrevDscommune] = useState(null);
  const [surface, setSurface] = useState(
    <CircularProgress color="primary" thickness={6} size={24} />
  );
  const [surfaceTech, setSurfaceTech] = useState({});
  const [nbPlanteurs, setNbPlanteurs] = useState(0);
  const [communes, setCommunes] = useState([]);
  const [communesTech, setCommunesTech] = useState({});
  const [showSecteurCircuit, setShowSecteurCircuit] = useState(false);

  // title
  if (donnee == SECTEUR_DONNEE_COMMUNE) {
    name = `${selected.get('nom')} (${selected.get('insee_com')})`;
  } else if (donnee == SECTEUR_DONNEE_SECTEUR) {
    name = selected.get('secteur') !== '' ? `${selected.get('secteur')}` : 'Sans secteur';
  } else if (donnee == SECTEUR_DONNEE_TECHNICIEN) {
    name = selected.get('technicien');
  } else if (donnee == SECTEUR_DONNEE_USINE) {
    name = `${selected.get('nomusl')}`;
  }

  if (
    selected.get('secteur') !== prevSecteur ||
    selected.get('insee_com') !== prevCommune ||
    donnee !== prevDonnee ||
    dscommune !== prevDscommune
  ) {
    if (donnee == SECTEUR_DONNEE_COMMUNE) {
      const surfaceTotalCommune = dscommune
        .filter((d) => d.codeinsee === selected.get('insee_com'))
        .map((d) => parseFloat(toDecimalString(d.surfaceEnqueteBetterave, 2)))
        .reduce((acc, obj) => acc + obj, 0);

      setCommunes([]);
      setSurface(surfaceTotalCommune.toFixed(2));
    } else if (donnee == SECTEUR_DONNEE_SECTEUR) {
      communes.length = 0;
      const surfaceTotalSecteur = dscommune
        .filter((d) => d.usl === selected.get('usl'))
        .filter((d) => d.secteur === selected.get('secteur'))
        .filter((d) => d.surfaceEnqueteBetterave > 0)
        .sort((a, b) => a.groupe - b.groupe || a.ordre - b.ordre)
        .map((d) => {
          const surface = parseFloat(toDecimalString(d.surfaceEnqueteBetterave, 2));
          communes.push({ nom: d.nom, surface, codeinsee: d.codeinsee });

          return surface;
        })
        .reduce((acc, obj) => acc + obj, 0);

      setCommunes(communes);
      setSurface(surfaceTotalSecteur.toFixed(2));
    } else if (donnee == SECTEUR_DONNEE_TECHNICIEN) {
      const surfaceTotalTech = dscommune
        .filter((d) => d.technicien === selected.get('technicien'))
        .map((d) => {
          const surface = parseFloat(toDecimalString(d.surfaceEnqueteBetterave, 2));
          return surface;
        })
        .reduce((acc, obj) => acc + obj, 0);

      const planteurTotalTech = dscommune
        .filter((d) => d.technicien === selected.get('technicien'))
        .map((d) => {
          return parseInt(d.nbPlanteur);
        })
        .reduce((acc, obj) => acc + obj, 0);

      const communesParSecteur = dscommune
        .filter((d) => d.technicien === selected.get('technicien'))
        .reduce((acc, obj) => {
          if (obj.secteur === '') {
            obj.secteur = 'ZZ0';
          }

          if (acc[obj.secteur]) {
            acc[obj.secteur].push({
              nom: obj.nom,
              surface: parseFloat(toDecimalString(obj.surfaceEnqueteBetterave, 2)),
              nbplanteur: parseInt(obj.nbPlanteur),
              codeinsee: obj.codeinsee,
            });

            acc[obj.secteur + 'totalSurface'] += parseFloat(
              toDecimalString(obj.surfaceEnqueteBetterave, 2)
            );

            acc[obj.secteur + 'totalPlanteur'] += parseInt(obj.nbPlanteur);
          } else {
            acc[obj.secteur] = [
              {
                nom: obj.nom,
                surface: parseFloat(toDecimalString(obj.surfaceEnqueteBetterave, 2)),
                nbplanteur: parseInt(obj.nbPlanteur),
                codeinsee: obj.codeinsee,
              },
            ];

            acc[obj.secteur + 'totalSurface'] = parseFloat(
              toDecimalString(obj.surfaceEnqueteBetterave, 2)
            );

            acc[obj.secteur + 'totalPlanteur'] = parseInt(obj.nbPlanteur);
          }

          return acc;
        }, {});

      setSurface(surfaceTotalTech.toFixed(2));
      setNbPlanteurs(planteurTotalTech);
      setCommunesTech(communesParSecteur);
    } else if (donnee == SECTEUR_DONNEE_USINE) {
      const surfaceParSecteurUsl = dscommune
        .filter((d) => d.usl === selected.get('usl'))
        .reduce((acc, obj) => {
          if (obj.secteur === '') {
            obj.secteur = 'ZZ0';
          }

          if (acc[obj.secteur]) {
            acc[obj.secteur] += parseFloat(toDecimalString(obj.surfaceEnqueteBetterave, 2));
          } else {
            acc[obj.secteur] = parseFloat(toDecimalString(obj.surfaceEnqueteBetterave, 2));
          }

          return acc;
        }, {});

      const surfaceParTechUsg = dscommune
        .filter((d) => d.usg === selected.get('usg'))
        .reduce((acc, obj) => {
          if (acc[obj.technicien]) {
            acc[obj.technicien].totalSurface += parseFloat(
              toDecimalString(obj.surfaceEnqueteBetterave, 2)
            );
            acc[obj.technicien].totalPlanteur += parseInt(obj.nbPlanteur);
          } else {
            acc[obj.technicien] = {
              totalSurface: parseFloat(toDecimalString(obj.surfaceEnqueteBetterave, 2)),
              totalPlanteur: parseInt(obj.nbPlanteur),
            };
          }

          return acc;
        }, {});

      setSurface(surfaceParSecteurUsl);
      setSurfaceTech(surfaceParTechUsg);
    }

    if (selected.get('secteur') !== prevSecteur) {
      setShowSecteurCircuit(false);
    }

    setPrevSecteur(selected.get('secteur'));
    setPrevCommune(selected.get('insee_com'));
    setPrevDonnee(donnee);
    setPrevDscommune(dscommune);
  }

  useEffect(() => {
    setSecteurSelected(selected.get('secteur'));
  }, [selected]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '10px',
        padding: '10px',
      }}
    >
      {donnee == SECTEUR_DONNEE_COMMUNE && (
        <>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body1" component="div">
            <ul style={{ margin: '0 0 10px 0' }}>
              <li>
                Secteur : <strong>{prevSecteur}</strong>
              </li>
              <li>
                Surface (enquête betterave): <strong>{surface}</strong> Ha
              </li>
            </ul>
          </Typography>
        </>
      )}
      {donnee == SECTEUR_DONNEE_SECTEUR && (
        <>
          {prevSecteur !== '' && (
            <>
              <Typography variant="h6">Afficher le circuit du secteur</Typography>
              <Switch
                checked={showSecteurCircuit}
                onChange={(event, checked) => {
                  setShowSecteurCircuit(checked);
                  onSecteurSwitchChange(checked);
                }}
              />
            </>
          )}
          <Typography variant="h6">
            {name} {surface} Ha
          </Typography>
          <Typography variant="body1" component="div">
            <ul style={{ margin: '0 0 10px 0' }}>
              {communes.map((c, idx) => (
                <li key={idx}>
                  <strong>
                    {c.nom} ({c.codeinsee}) {c.surface} Ha
                  </strong>
                </li>
              ))}
            </ul>
          </Typography>
        </>
      )}
      {donnee == SECTEUR_DONNEE_TECHNICIEN && (
        <>
          <Typography variant="h6">
            {name} - {surface} Ha / {nbPlanteurs} planteurs
          </Typography>
          <Typography variant="body1" component="div">
            {Object.keys(communesTech)
              .filter((k) => !k.includes('total'))
              .map((secteur, idx) => (
                <div key={idx}>
                  <strong>
                    {secteur === 'ZZ0' ? 'Sans secteur' : secteur}
                    {' - '}
                    {communesTech[secteur + 'totalSurface'].toFixed(2)} Ha{' / '}
                    {communesTech[secteur + 'totalPlanteur']} planteur(s)
                  </strong>
                  <ul style={{ margin: '0 0 10px 0' }}>
                    {communesTech[secteur].map((c, idx) => (
                      <li key={idx}>
                        <strong>
                          {c.nom} ({c.codeinsee})
                          <br />
                          {c.surface} Ha{' / '}
                          {c.nbplanteur} planteur(s)
                        </strong>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </Typography>
        </>
      )}
      {donnee == SECTEUR_DONNEE_USINE && (
        <>
          <Button onClick={() => onGeneratePreview()}>Générer le fichier des modifications</Button>
          <Typography variant="h6">USL: {name}</Typography>
          <Typography variant="body1" component="div">
            <ul style={{ margin: '0 0 10px 0' }}>
              {!React.isValidElement(surface) &&
                Object.keys(surface)
                  .sort()
                  .map((s, idx) => (
                    <li key={idx}>
                      <strong>
                        {s === 'ZZ0' ? 'Sans secteur' : s} {parseFloat(surface[s]).toFixed(2)} Ha
                      </strong>
                    </li>
                  ))}
            </ul>
          </Typography>
          <Divider />
          <Typography variant="h6">USG: {name}</Typography>
          <Typography variant="body1" component="div">
            <ul style={{ margin: '0 0 10px 0' }}>
              {Object.keys(surfaceTech)
                .sort()
                .map((t, idx) => (
                  <li key={idx}>
                    <strong>
                      {t === '' ? 'Sans RRC' : t}{' '}
                      {parseFloat(surfaceTech[t].totalSurface.toFixed(2))} Ha{' / '}
                      {surfaceTech[t].totalPlanteur} planteur(s)
                    </strong>
                  </li>
                ))}
            </ul>
          </Typography>
        </>
      )}
    </Paper>
  );
};

export default CommuneInfo;
